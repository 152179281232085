import { Box, Button, Container, Grid, Link, Typography, useTheme } from "@mui/material";
import { cmsApiClient, CmsApiAcl } from "@cm-websites/common/dist/cms-api";
import { Editions, HeroHeaderCm04, Testimonials } from "../front-page";
import {
  Explainer,
  InstagramFeed,
  InstagramPost,
  ProductSelection,
  ResponsiveLineBreak,
} from "@cm-websites/common";
import { Footer } from "../footer";
import { getInstagramPosts } from "@cm-websites/common/dist/instagram-feed/api";
import { HEADER_ITEMS } from "../header";
import { MOCK_QUOTES } from "../front-page/testimonials/mock";
import { NextSeo, SocialProfileJsonLd } from "next-seo";
import { serverSideTranslations } from "next-i18next/serverSideTranslations";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import i18nConfig from "../../next-i18next.config";
import Image from "next/legacy/image";
import ModdAkteMord from "../../public/images/front-page/tatort-krimispiel-hidden-clues-akte-mord.jpg";
import MoodGeschenk from "../../public/images/front-page/krimidinner-verschenken.jpg";
import NextLink from "next/link";
import MoodSpieleWand from "../../public/images/front-page/krimidinner-spiel.jpg";
import React from "react";
import type { CmsApiModel } from "@cm-websites/common/dist/cms-api";
import type { GetStaticProps } from "next";
import type { Product } from "@cm-websites/common";

type HomeProps = {
  featuredProduct: Product;
  instagramPosts: InstagramPost[];
  products: Product[];
};

export const HomePage: React.FC<HomeProps> = ({ featuredProduct, instagramPosts = [], products }) => {
  const theme = useTheme();

  return (
    <React.Fragment>
      <NextSeo
        title="Das Krimidinner für Zuhause – Culinario Mortale"
        description="Veranstalte mit deinen Freunden das perfekte Krimidinner für Zuhause. Finde spannende Krimispiele für 5–8 Personen. Für nur 22,95 € + kostenloser Versand."
        openGraph={{
          type: "website",
          url: "https://www.culinario-mortale.de",
          title: "Das Krimidinner für Zuhause – Culinario Mortale®",
          description:
            "Veranstalte dein eigenes Krimi-Dinner Zuhause und erleben mit deinen Freunden einen unvergesslichen Abend für 5-8 Personen.",
          images: [
            {
              url: "", // TODO
              width: 1200,
              height: 627,
              alt: "Das Krimidinner für Zuhause – Culinario Mortale®",
            },
          ],
        }}
      />
      <SocialProfileJsonLd
        type="Organization"
        name="Culinario Mortale"
        url="https://www.culinario-mortale.de"
        sameAs={[
          "https://www.facebook.com/culinario.mortale",
          "https://www.instagram.com/culinario.mortale",
          "https://www.pinterest.de/culinariomortale",
        ]}
      />
      <HeroHeaderCm04 navItems={HEADER_ITEMS} />
      <ProductSelection
        id="krimidinner-bestellen"
        products={products}
        featuredProductId="CM09DE"
        recommendedProductIds={["CM06DE", "CM07DE"]}
      />
      <Explainer />
      <Editions />
      <Box component="section" sx={{ position: "relative" }}>
        <Container
          maxWidth="xl"
          sx={{ pt: { xs: 8, sm: 10, md: 16 }, pb: { xs: 6, sm: 8, md: 10 }, overflowX: "hidden" }}
        >
          <Grid alignItems="center" container spacing={{ xs: 6, sm: 10 }}>
            <Grid
              item
              xs={12}
              md={6}
              sx={{
                "> span": { borderRadius: theme.shape.borderRadius, overflow: "hidden" },
                pb: 0,
              }}
            >
              <Image
                alt="Drei Krimispiele an einer Wand"
                placeholder="blur"
                title="Krimidinner Spiele"
                src={MoodSpieleWand}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Typography variant="h2" sx={{ pb: { xs: 3, md: 5 }, hyphens: "none" }}>
                Serviere einen Mord <ResponsiveLineBreak md />
                zum Dinner
              </Typography>
              <Typography variant="body1" paragraph>
                Mit unseren Krimidinner Spielen für Zuhause erlebst du einen Abend voller Spannung,
                Intrigen und Überraschungen – direkt an deinem Esstisch. Unser Spielprinzip ist einfach
                zu verstehen. Da die <NextLink href="/spielregeln-und-ablauf">Spielregeln</NextLink> auf
                nur eine Seite passen, finden sich auch Krimispiel-Beginner total leicht zurecht.
              </Typography>

              <Typography variant="body1" paragraph>
                Mit Hilfe des Zugangscodes, der deinem Krimispiel beiliegt, kannst du die
                Rollenbeschreibungen im Vorfeld bequem{" "}
                <NextLink href="/vorbereitung">herunterladen</NextLink> und an deine Gäste verteilen. So
                kann sich jeder Gast vor dem Krimidinner in Ruhe zu Hause in seine Rolle einlesen und
                sich passend verkleiden. Und mit unseren{" "}
                <NextLink href="/rezeptideen">Menüvorschlägen</NextLink> wird deine Dinnerparty auch
                kulinarisch ein voller Erfolg.
              </Typography>
            </Grid>
          </Grid>
        </Container>
      </Box>
      <Box component="section" sx={{ position: "relative" }}>
        <Container
          maxWidth="xl"
          sx={{ pt: { xs: 0, sm: 0, md: 10 }, pb: { xs: 0, sm: 10 }, overflowX: "hidden" }}
        >
          <Grid
            alignItems="center"
            container
            direction={{ xs: "column-reverse", md: "row" }}
            spacing={{ xs: 6, sm: 10 }}
          >
            <Grid item xs={12} md={6}>
              <Typography variant="h2" sx={{ pb: { xs: 3, md: 5 }, hyphens: "none" }}>
                Das perfekte Geschenk <ResponsiveLineBreak md lg xl />
                für Krimi-Fans
              </Typography>
              <Typography variant="body1" paragraph>
                Culinario Mortale Fälle eignen sich ideal als Geschenk. Schenke Freunden, Familie oder
                Kollegen ein Krimi-Dinner zum Selbermachen. Egal, ob zum Geburtstag, zu Silvester oder
                für die nächste Grillparty: Unsere Krimispiele beinhalten alle Materialien, die du für
                eine unvergesslichen Abend brauchst.
              </Typography>
              <Typography variant="body1" paragraph>
                Und für den Fall, dass es einmal schnell gehen muss: Nutze unsere{" "}
                <NextLink href="/online-krimidinner">Online-Version</NextLink> oder finde mit Hilfe der{" "}
                <NextLink href="/haendler-finden">Händlersuche</NextLink> ein Geschäfts ganz in deiner
                Nähe, das unsere Krimidinner führt.
              </Typography>
            </Grid>

            <Grid
              item
              xs={12}
              md={6}
              sx={{
                "> span": { borderRadius: theme.shape.borderRadius, overflow: "hidden" },
                pb: 0,
              }}
            >
              <Image
                alt="Krimidinner verschenken an Freunde"
                placeholder="blur"
                title="Krimidinner für Zuhause"
                src={MoodGeschenk}
              />
            </Grid>
          </Grid>
        </Container>
      </Box>
      <Testimonials quotes={MOCK_QUOTES} sectionContainer={{ mt: 6, mb: 3 }} />
      <Box component="section" sx={{ position: "relative" }}>
        <Container
          maxWidth="xl"
          sx={{ pt: { xs: 6, sm: 8, md: 10 }, pb: { xs: 10, sm: 14, md: 16 }, overflowX: "hidden" }}
        >
          <Grid
            alignItems="center"
            container
            direction={{ xs: "column", md: "row" }}
            spacing={{ xs: 6, sm: 10 }}
          >
            <Grid
              item
              xs={12}
              md={6}
              sx={{
                pb: 0,
                "> span": { borderRadius: theme.shape.borderRadius, overflow: "hidden" },
              }}
            >
              <Image alt="" placeholder="blur" title="" src={ModdAkteMord} />
            </Grid>
            <Grid item xs={12} md={6}>
              <Typography variant="h2" sx={{ pb: { xs: 3, md: 5 }, hyphens: "none" }}>
                Unsere Krimispiele für
                <ResponsiveLineBreak md lg xl /> Detektiv- und Escape Fans
              </Typography>
              <Typography variant="body1" paragraph>
                Du liebst Exit- und Escape Room Spiele? Dann werde selbst zum Ermittler in einer Cold
                Case Einheit und löse spannende Mordfälle mit unserer neuen Spielereihe AKTE MORD
                <sup>®</sup>.
              </Typography>

              <Typography variant="body1" paragraph>
                Du erhältst eine realistische Fallakte mit über 59 Beweismitteln, darunter
                Polizeiberichte, Untersuchungsergebnisse, Fotos, Zeugenvernehmungen und vieles mehr. Höre
                dir die Vernehmungsprotokolle der Verdächtigen als Audio-Datei an, finde die Hidden Clues
                und löse drei herausfordernde Aufgaben. Bei diesem spannenden Detektivspiel für 1 – 5
                Personen bist du dafür verantwortlich, dass die Opfer nach all den Jahren endlich
                Gerechtigkeit erfahren.
              </Typography>

              <Button
                endIcon={<ChevronRightIcon />}
                href="https://www.akte-mord.de"
                LinkComponent={Link}
                sx={{ mt: 3, width: { xs: "100%", sm: "unset" } }}
                variant="outlined"
              >
                Cold-Cases entdecken
              </Button>
            </Grid>
          </Grid>
        </Container>
      </Box>
      <InstagramFeed posts={instagramPosts} />
      <Footer />
    </React.Fragment>
  );
};

export default HomePage;

export const getStaticProps: GetStaticProps = async ({ locale }) => {
  const instagramPosts = await getInstagramPosts();

  const fields = [
    "*.*",
    "printVersionImages.*",
    "printVersionImages.directus_files_id.mask",
    "printVersionImages.directus_files_id.id",
    "printVersionImages.directus_files_id.description",
    "onlineVersionImages.*",
    "onlineVersionImages.directus_files_id.mask",
    "onlineVersionImages.directus_files_id.id",
    "onlineVersionImages.directus_files_id.description",
  ];

  const { data } = await cmsApiClient.get<{
    data: CmsApiModel.components["schemas"]["ItemsCulinarioMortale"][];
  }>(`/items/Culinario_Mortale?filter={"language":"DE"}&fields=${fields.join(",")}`);

  const products = (
    await Promise.all(
      data.data.map((p) => CmsApiAcl.product(p, "print", { origin: "startseite print" }))
    )
  ).sort((a, b) => {
    const nameA = a.id.toUpperCase(); // ignore upper and lowercase
    const nameB = b.id.toUpperCase(); // ignore upper and lowercase

    // sort in an descending order
    if (nameA < nameB) {
      return 1;
    }
    if (nameA > nameB) {
      return -1;
    }

    // names must be equal
    return 0;
  });
  const featuredProduct = products[0];

  return {
    props: {
      ...(locale ? await serverSideTranslations(locale, ["c-common"], i18nConfig) : {}),
      products,
      featuredProduct,
      instagramPosts,
    },
    revalidate: 259200,
  };
};
