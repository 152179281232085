import { Box, Button, Container, Grid, SxProps, Typography } from "@mui/material";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import EditionsImage from "../../../public/images/front-page/krimidinner-fuer-zuhause-krimispiel.png";
import Image from "next/image";
import NextLink from "next/link";
import React from "react";

type EditionsProps = { sectionContainer?: SxProps };

export const Editions: React.FC<EditionsProps> = ({ sectionContainer = {} }) => {
  const bgColor = "#DBF2FD";

  return (
    <Box
      component="section"
      id="editions"
      sx={{
        backgroundColor: bgColor,
        boxShadow: "sectionVertical",
        position: "relative",
        zIndex: 5,
        ...sectionContainer,
      }}
    >
      <Container
        maxWidth="xl"
        sx={{
          pt: { xs: 8, sm: 8, md: 10, lg: 14, xl: 16 },
          pb: { xs: 6, sm: 6, md: 8, lg: 10, xl: 12 },
        }}
      >
        <Typography variant="h2" sx={{ pb: 6 }}>
          Gedruckt oder online
        </Typography>
        <Grid
          alignItems={{ xs: "center", lg: "flex-start" }}
          container
          direction={{ xs: "column-reverse", sm: "row" }}
          spacing={{ xs: 2, sm: 6, lg: 12 }}
        >
          <Grid item xs={12} md={6} lg={6}>
            <Typography component="h3" variant="h5">
              Gedruckt
            </Typography>
            <Typography variant="body1" paragraph>
              Unsere gedruckten Krimidinner werden zu dir nach Hause versandt. Innerhalb von 3 – 5
              Werktagen erhältst du unsere Box mit allen Materialien, die du für deine Krimiparty
              benötigst. Mit dem im Spiel befindlichen Zugangscode kannst du vorab deinen Gästen ihre{" "}
              <NextLink href="/vorbereitung">Rollenbeschreibung</NextLink> per E-Mail zukommen lassen und dir in
              Ruhe Gedanken über die Ausgestaltung deines Krimmidinners machen.
            </Typography>
            <Typography component="h3" variant="h5">
              Online
            </Typography>
            <Typography variant="body1" paragraph>
              Wenn’s mal schnell gehen muss: Mit Culinario Mortale <NextLink href="/online-krimidinner">ONLINE</NextLink> kannst du
              unsere Krimidinner Spiele auch online durchführen. Alle Teilnehmer können ihr Spielheft
              dabei bequem auf ihrem Smartphone, Tablet oder Computer abrufen. Auf diese Weise kannst du
              das Krimispiel auch einfach über eine Videokonferenz (Skype, Zoom, o. ä.) veranstalten.
              Deinen Zugangscode erhältst du direkt im Anschluss an deine Bestellung, sodass du mit
              deinem Krimidinner Spiel sofort loslegen kannst.
            </Typography>

            <Button
              endIcon={<ChevronRightIcon />}
              href="/online-krimidinner"
              LinkComponent={NextLink}
              sx={{ mt: 3, width: { xs: "100%", sm: "unset" } }}
              variant="contained"
            >
              Mehr erfahren
            </Button>
          </Grid>
          <Grid item xs={12} sm={0} md={6} lg={6}>
            <Box sx={{ display: "flex", width: "100%", alignItems: "center" }}>
              <Image
                alt="Ein gedrucktes Krimidinner und die zugheörige Online-Version"
                title="Egal ob als Online-Krimidinner oder gedruckte Version: Krimispiele von Culinario Mortale sind immer eine gute Wahl"
                sizes={`
                    (max-width: 767px) 80vw,
                    (min-width: 768px) 35vw,
                  `}
                src={EditionsImage}
                style={{ width: "100%", height: "auto" }}
              />
            </Box>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};
